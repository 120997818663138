<template>
	<div>
    <div>
      <Header></Header>
    </div>
    <div id="admindiv1">
      <div style="margin-top: 15px;font-family: '苹方',serif;font-size: 13px;margin-left: 10px">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item style="font-size: 13px" :to="{ path: '/manage' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item style="font-size: 13px">一键巡查</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
		<div style="display: flex;justify-content: space-between;">
			<div style="margin-left: 100px;margin-top: 10px;margin-bottom: 20px;">
				<el-tag style="margin-right: 20px;">监控点共计{{ all + allno }}</el-tag>
				<el-tag style="margin-right: 20px;">监控点在线{{ all }}</el-tag>
				<el-tag style="margin-right: 20px;" type="danger">监控点掉线{{ allno }}</el-tag>
				<el-tag style="margin-right: 20px;">监控点在线率{{ numFilter(all / (all + allno)) * 100 }}%</el-tag>
			</div>
			<div style="margin-right: 100px;margin-top: 10px;">
        <el-tag>目前库中监控点数： {{ codenum }}</el-tag>
        <el-button @click="getcode" type="warning" size="small">更新视屏监控点数</el-button>
				<el-button @click="getno" size="small">点击切换监控状态</el-button>
        <el-button @click="geturl">测试</el-button>
			</div>
		</div>

		<div v-if="online" style="margin-left: 250px">
			<el-table :data="tableData" style="width: 80%">
				<el-table-column prop="cn" label="监控点名称" width="280" />
				<el-table-column prop="collectTime" label="巡查时间" width="280" />
				<el-table-column label="是否在线">
					<el-tag type="success" effect="dark" size="mini">在线</el-tag>
				</el-table-column>
			</el-table>
			<div v-if="online" style="float: right;margin-right: 30px;margin-top: 5px;display: flex">
				<div style="font-size: 12px;margin-right: 10px;margin-top: 10px">共计 {{ all }}</div>
				<el-pagination :small="true" background layout="prev, pager, next" :total='pagetotal'
					@next-click="change" @prev-click="change" @current-change="change">
				</el-pagination>
			</div>
		</div>
		<!-- 不在线 -->
		<div v-if="!online" style="margin-left: 250px">
			<el-table :data="tableDatano" style="width: 80%">
				<el-table-column prop="cn" label="监控点名称" width="280" />
				<el-table-column prop="collectTime" label="巡查时间" width="280" />
				<el-table-column label="是否在线">
					<el-tag type="danger" effect="dark" size="mini">掉线</el-tag>
				</el-table-column>
			</el-table>
			<div v-if="!online" style="float: right;margin-right: 30px;margin-top: 5px;display: flex">
				<div style="font-size: 12px;margin-right: 10px;margin-top: 10px">共计 {{ allno }}</div>
				<el-pagination :small="true" background layout="prev, pager, next" :total='pagetotalno'
					@next-click="change2" @prev-click="change2" @current-change="change2">
				</el-pagination>
			</div>
			<div style="margin-top: 20px;">
				<el-button type="warning" @click="oneup">一键上报</el-button>
				<el-button type="danger" @click="sendsms">发送短信</el-button>
			</div>
		</div>
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="Tips"
          width="30%"
          :before-close="oneup"
      >
      </el-dialog>
    </div>
	</div>
</template>

<script>
	import moment from 'moment';
  import { ElMessageBox } from 'element-plus'
	import {
		mapGetters
	} from "vuex";
	import {
		ElMessage
	} from 'element-plus'
  import Header from "@/views/header";
	export default {
    components: {Header},
		name: "autovideo",
		data() {
			return {
				tableData: [],
				tableDatano: [],
				pagetotal: '',
				pagetotalno: '',
				all: '',
				allno: '',
				showall: '',
				online: true, // 默认显示在线列表
				apms: '',
				code: [],
				bdst: [],
				js: true, //上报是否正确,
        dialogVisible: false,
        code1: [], // 更新监控点
        codenum: ''
			}
		},
		computed: {
			...mapGetters(['GET_USER'])
		},
		created() {
			this.axios.post("/videoquestion/autovoi", (response) => {
        console.log(response)
				this.tableData = JSON.parse(response.obj).data.list;
				this.pagetotal = JSON.parse(response.obj).data.totalPage * 10;
				this.all = JSON.parse(response.obj).data.total;
				for (let i = 0; i < this.tableData.length; i++) {
					this.tableData[i].collectTime = moment(this.tableData[i].collectTime).format(
						'YYYY-MM-DD HH:mm:ss')
				}
			}, {
				page: 1,
				status: 1,
        pageSize: 10
			});
			this.axios.post("/videoquestion/autovoi", (response) => {
				this.tableDatano = JSON.parse(response.obj).data.list;
				this.pagetotalno = JSON.parse(response.obj).data.totalPage * 10;
				this.allno = JSON.parse(response.obj).data.total;
        console.log(JSON.parse(response.obj).data.totalPage)
				for (let i = 0; i < this.tableDatano.length; i++) {
					this.tableDatano[i].collectTime = moment(this.tableDatano[i].collectTime).format(
						'YYYY-MM-DD HH:mm:ss')
				}
			}, {
				page: 1,
				status: 0,
        pageSize: 10
			});
      this.axios.get("/operatingpoint/getcodenum", (response) => {
        this.codenum = response.obj
      })
		},
		methods: {
			setNowTimes() {
				let myDate = new Date()
				let wk = myDate.getDay()
				let yy = String(myDate.getFullYear())
				let mm = myDate.getMonth() + 1
				let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
				let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
				let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
				let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
				let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
				let week = weeks[wk]
				this.nowDate = yy + '-' + mm + '-' + dd
				this.nowTime = hou + ':' + min
				this.nowWeek = week
			},
			change(val) {
				this.axios.post("/videoquestion/autovoi", (response) => {
					this.tableData = JSON.parse(response.obj).data.list;
					this.pagetotal = JSON.parse(response.obj).data.totalPage * 10;
					this.all = JSON.parse(response.obj).data.total;
					for (let i = 0; i < this.tableData.length; i++) {
						this.tableData[i].collectTime = moment(this.tableData[i].collectTime).format(
							'YYYY-MM-DD HH:mm:ss');
					}
				}, {
					page: val * 1,
					status: 1,
					pageSize: 10
				})
			},
			change2(val) {
				this.axios.post("/videoquestion/autovoi", (response) => {
					this.tableDatano = JSON.parse(response.obj).data.list;
					this.pagetotalno = JSON.parse(response.obj).data.totalPage * 10;
					this.allno = JSON.parse(response.obj).data.total;
					for (let i = 0; i < this.tableDatano.length; i++) {
						this.tableDatano[i].collectTime = moment(this.tableDatano[i].collectTime).format(
							'YYYY-MM-DD HH:mm:ss');
					}
				}, {
					page: val * 1,
					status: 0,
					pageSize: 10
				})
			},
			getno() {
				this.online = !this.online
			},
			numFilter(value) {
				const realVal = parseFloat(value).toFixed(2);
				return realVal;
			},
      oneup(){
        ElMessageBox.confirm('是否上报？')
            .then(() => {
              this.upque();
            })
            .catch(() => {
            })
      },
			upque() {
				this.setNowTimes();
				if (this.nowTime.slice(0, 2) >= 0 && this.nowTime.slice(0, 2) <= 12) {
					this.apms = 0;
				} else {
					this.apms = 1;
				}
				this.axios.post("/videoquestion/autovoi", (response) => {
					this.code = JSON.parse(response.obj).data.list;
					for (let i = 0; i < this.code.length; i++) {
            console.log(this.code[i].indexCode)
						this.axios.post("/videoquestion/getbdsts", (response1) => {
							this.axios.post("/videoquestion/savev", (response2) => {
								if (!response2.obj) {
									ElMessage({
										message: '上报有误，请联系管理员',
										type: 'warning',
									})
								}
								if (i === this.code.length - 1 && response2.obj) {
									ElMessage({
										message: '上报成功',
										type: 'success',
									})
								}

							}, {
								operatingpoint: this.code[i].cn,
								question: '掉线',
								time: this.nowDate + ' ' + this.nowTime,
								username: this.GET_USER,
								bdst: response1.obj,
								apm: this.apms,
								status: 0
							})
						}, {
							codes: this.code[i].indexCode
						})
					}

				}, {
					page: 1,
					status: 1,
					pageSize: this.allno
				})
			},
			sendsms(){
        this.axios.post("/videoquestion/autovoi", (response) => {
          this.axios.post("/videoquestion/send", (response) => {
            console.log(response)
          }, {
            pagesize: JSON.parse(response.obj).data.list.length
          })
        },  {
          page: 1,
          status: 1,
          pageSize: this.allno
        })
			},
      // getcode(){
      //   this.axios.post("/operatingpoint/getcode", (response) => {
      //     this.code1 = response.obj.data.list;
      //     if (this.code1){
      //       for (let i = 0; i < this.code1.length; i++){
      //         this.axios.post("/operatingpoint/upcode", (response) => {
      //           ElMessage({
      //             message: '更新成功',
      //             type: 'success',
      //           })
      //         },{
      //           cameraName: this.code1[i].cameraName,
      //           cameraIndexCode: this.code1[i].cameraIndexCode
      //         })
      //       }
      //     }
      //
      //   },{
      //     pageSize: (this.all + this.allno) * 1
      //   })
      // },
      geturl(){
        this.axios.post('/videoquestion/getcode', (response) => {
          let arr = JSON.parse(response.obj);
          console.log(arr.data.list)
          arr.data.list.forEach((item) => {
            this.axios.post('/operatingpoint/addvoidecode', (response) => {
              console.log(response)
            },{
              cameraName: item.name,
              cameraIndexCode: item.indexCode,
              bdstype: item.regionPathName
            })
          })
        },{
          pageSize: 200
        })
      }
		}
	}
</script>

<style scoped>
#admindiv1{
  border-radius: 5px;
  background-color: white;
  display: flex;
  width: 95%;
  height: 40px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  justify-content: space-between;
}
</style>
